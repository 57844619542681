import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Apiurl from './apiurl';
import axios from 'axios';

// Function to generate a random Indian phone number
function generateRandomPhoneNumber() {
  const firstDigit = 6 + Math.floor(Math.random() * 4); // Indian phone numbers start with 6, 7, 8, or 9
  let phoneNumber = `${firstDigit}`;
  for (let i = 0; i < 9; i++) {
      phoneNumber += Math.floor(Math.random() * 10);
  }
  return phoneNumber;
}

// Function to generate a random valid email ID
function generateRandomEmail(name) {
  const domains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com', 'icloud.com']; // Common email domains
  const domain = domains[Math.floor(Math.random() * domains.length)];
  return `${name}@${domain}`;
}

// Function to generate a random name
function generateRandomName() {
  const firstNameList = ['John', 'Jane', 'Michael', 'Emily', 'David', 'Sarah', 'Daniel', 'Jessica', 'Matthew', 'Amanda', 'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez'];
  const firstName = firstNameList[Math.floor(Math.random() * firstNameList.length)];
  return `${firstName}`;
}

const Payments2 = () => {
  const apiu = Apiurl();
  const params = useParams();
  const { userId } = params;
  console.log(userId);

  useEffect(() => {
    const referringDomain = document.referrer.split('/')[2];
    console.log(referringDomain);
    const allowedDomains = ['139.59.77.211', 'localhost:8080', 'localhost:3000', 'casinohero.bet'];

    if (!allowedDomains.includes(referringDomain)) {
      // Redirect or show an error message
      // Example: window.location.href = '/error';
      alert('You are not authorized to access this page.');
    } else {
      axios.get(apiu + "/latest_transaction/paytme/" + userId).then((response) => {
        const formData = new FormData();
        formData.append("id", response.data.id);
        console.log(response.data.id);
        console.log(response.data.amount);

        const randomName = generateRandomName();
        const randomPhoneNumber = generateRandomPhoneNumber();
        const randomEmail = generateRandomEmail(randomName.toLowerCase());

        console.log("Random Name:", randomName);
        console.log("Random Indian Phone Number:", randomPhoneNumber);
        console.log("Random Email ID:", randomEmail);

        const rawdata = JSON.stringify({"userContactNumber": randomPhoneNumber, "merchantTransactionId": response.data.id.toString(), "amount": parseInt(response.data.amount), "name": randomName, "email": randomEmail});

        console.log(rawdata);
        axios({method: 'post', url: 'https://apis.paytme.com/v1/merchant/payin', headers: { 
            'x-api-key': 'afc145ab157fe62000acb8d093c7d387',
            'Content-Type': 'application/json'
          }, data:
          rawdata}).then((response2) => {
            const upiurl = response2.data.data.upiurl;
            const paytmetid = response2.data.data.transaction_id;
            console.log(response2.data.data.upiurl);
            console.log(response2.data.data.transaction_id);

            // Regular expression pattern to match the values
            const pattern = /(?:&|^)(tn|tr|pa)=([^&]+)/g;

            let match;
            const values = {};

            // Executing the regex on the URL and storing the matches in 'values' object
            while ((match = pattern.exec(upiurl)) !== null) {
                const key = match[1];
                const value = match[2];
                values[key] = value;
            }

            // Extracted values
            const tn = values['tn'];
            const tr = values['tr'];
            const pa = values['pa'];

            console.log("tn:", tn);
            console.log("tr:", tr);
            console.log("pa:", pa);

            const durl = "http://13.201.33.147/dpayments?tid=" + paytmetid + "&tn=" + tn + "&tr=" + tr + "&uid=" + pa;
            // window.location.href = durl;

          //   const durl = response2.data.data.upiurl;
          //   console.log(durl);
          //   const tidValue = durl.substring(durl.indexOf("tid=") + 4, durl.indexOf("&dt"));
          //   console.log(tidValue);
            formData.append("oid", paytmetid);
            axios.post(apiu + "/transaction_id", formData).then((response3) => {
              console.log(response3);
              window.location.href = durl;
            }).catch((error) => {console.log(error);});
        }).catch((error) => {console.log(error);});
      }).catch((error) => {console.log(error);});
    }
  }, []);

  return (
    <div>
      {/* Your restricted page content */}
      <h1>Redirecting...</h1>
    </div>
  );
};

export default Payments2;
