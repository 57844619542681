import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Apiurl from './apiurl';
import axios from 'axios';

const Payments = () => {
  const apiu = Apiurl();
  const params = useParams();
  const { userId } = params;
  console.log(userId);

  useEffect(() => {
    const referringDomain = document.referrer.split('/')[2];
    console.log(referringDomain);
    const allowedDomains = ['139.59.77.211', 'localhost:8080', 'localhost:3000', 'casinohero.bet'];

    if (!allowedDomains.includes(referringDomain)) {
      // Redirect or show an error message
      // Example: window.location.href = '/error';
      alert('You are not authorized to access this page.');
    } else {
      axios.get(apiu + "/latest_transaction/bazorpay/" + userId).then((response) => {
        const formData = new FormData();
        formData.append("id", response.data.id);
        console.log(response.data.id);
        console.log(response.data.amount);
        axios.post('https://api.bazorpay.com/transactions/createtransaction?merchant_id=MR_0000001&api_key=hD-MkTj9zxIOciv9O65PCQ1bynAPSgKG14kuGOmf2bXQIAIR&transaction_id=' + response.data.id + '&amount=' + response.data.amount + '&user_name=&mobile_no=&email=').then((response2) => {
          const durl = response2.data.success.desktopurl;
          console.log(durl);
          const tidValue = durl.substring(durl.indexOf("tid=") + 4, durl.indexOf("&dt"));
          console.log(tidValue);
          formData.append("oid", tidValue);
          axios.post(apiu + "/transaction_id", formData).then((response3) => {
            console.log(response3);
            window.location.href = durl;
          }).catch((error) => {console.log(error);});
        }).catch((error) => {console.log(error);});
      }).catch((error) => {console.log(error);});
    }
  }, []);

  return (
    <div>
      {/* Your restricted page content */}
      <h1>Redirecting...</h1>
    </div>
  );
};

export default Payments;
