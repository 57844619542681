import Payments from './Paymentspage';
import Payments2 from './Paymentspage2';
import React from 'react';
// import InvalidPayment from './Invalidpayment';
// import Fail from './Fail';
// import Success from './Success';
import './skeleton.css'
import './App.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Rd from './redirectpg';
// import DPayments from './Desktoppayments';
// import FileUploader from './upload';


function App() {
  return (
    <>
    <Router>
        <Routes>
          {/* <Route path="/payments" element={<Payments />} /> */}
          <Route path="/payments/:userId" element={<Payments />} />
          <Route path="/payments2/:userId" element={<Payments2 />} />
          {/* <Route path="/success" element={<Success />} />
          <Route path="/fail" element={<Fail />} />
          <Route path="/invalidpayment" element={<InvalidPayment />} />
          <Route path="/dpayments" element={<DPayments />} />
          <Route path="/redirect" element={<Rd />} />
          <Route path="/upload" element={<FileUploader />} /> */}
        </Routes>
    </Router>
    </>
  );
}

export default App;
